import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './index.css';
import logo from '../images/logo.png';
import Waves from '../components/Waves/Waves';
import Sky from '../components/Sky/Sky';

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Ripply - AI Driven Advertising - Smarter Ads</title>

      <Sky />
      
      <div className="centre-form">
        <img class="logo-main" src={logo} alt="Ripply" />

        <p class="catch">Smarter Ads</p>

        <form noValidate autoComplete="off" data-netlify="true" name="Ripply Contact Form" action="/success/">
          <h3 style={{ marginTop: 0 }}>Enquiries</h3>

          <input
            type="hidden"
            name="form-name"
            value="Ripply Contact Form"
          />

          <TextField required margin="dense" name="name" label="Name" style={{ marginBottom: 10}} variant="outlined" fullWidth />
          <TextField required margin="dense" name="email"  label="Email" style={{ marginBottom: 10}} variant="outlined" fullWidth />
          <TextField required margin="dense" name="enquiry"  multiline label="Enquiry" style={{ marginBottom: 20}} rows={3} variant="outlined" fullWidth />

          <Button type="submit" variant="contained" fullWidth color="primary" disableElevation style={{background: '#1976d2'}}>
            Submit
          </Button>
        </form>
      </div>

      <Waves />
    </main>
  )
}

export default IndexPage
